import  React, {  useEffect, useState, useContext, useRef } from 'react';
import { Switch, Toaster, Menu, MenuItem, Alignment, Button, Navbar, NavbarGroup, NavbarHeading, NavbarDivider } from "@blueprintjs/core";
import { login, logout } from '../features/userSlice';
import { selectMe, selectOrgUsers, loadMe, loadMeOrgs, selectMyOrg, mySelectedOrg} from '../features/orgsSlice';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { User } from '../types/User';
import { useNavigate } from "react-router-dom";
import { v4 } from 'uuid'
import { authorizeUrl } from '../auth/authConfig'
import { logoutOAuth, refreshTokens, TokenPayload, getEmailFromIdToken } from '../auth/validator'
import { useInterval } from 'usehooks-ts'
import { Classes, Popover2 } from "@blueprintjs/popover2";
import { useLazyGetMeQuery, useLazyGetMeOrgsQuery } from '../services/usersOrgsApi';

import { AppToaster } from "../Toaster";
import { DarkModeContext } from '../../App'
import { featureConfig } from '../featureConfig'

export const SiteNav = () => {
  const user = useAppSelector((state) => state.userData.user)
  const myOrg = useAppSelector(mySelectedOrg)
  const me = useAppSelector(selectMe)
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const [tokenRefresher, setTokenRefresher] = useState(null)
  const [ getMe, meData, meLPI ] = useLazyGetMeQuery()
  const [ getMeOrgs, meOrgsData, meOrgsLPI ] = useLazyGetMeOrgsQuery()

  const {darkModeState, setDarkModeState} = useContext(DarkModeContext)

  useEffect(() => {
    if ((!meData || meData.status !== 'fulfilled' ) && user && user.jwt) {
      if(meData.status !== "rejected") {
        getMe(null)
      } else {
        AppToaster.show({ message: "This user is not yet authorized to access this application" });
      }
    }
    if(meData && meData.status == 'fulfilled') {
      dispatch(loadMe(meData.data))
    }
  }, [meData, user, getMe])

  useEffect(() => {
    if ((!meOrgsData || meOrgsData.status !== 'fulfilled' ) && user && user.jwt && me) {
      if(meOrgsData.status !== 'rejected'){
        getMeOrgs(null)
      }
    }
    if(meOrgsData && meOrgsData.status == 'fulfilled') {
        dispatch(loadMeOrgs(meOrgsData.data))
        dispatch(selectMyOrg(meOrgsData.data[0]['org']['uuid']))
    }
  }, [meOrgsData, user, getMeOrgs, me])

  const refreshIntervalHandler = () => {
    if ((user?.expiresAt || 0) < Date.now() + 10 * 60 * 1000 && user?.refreshToken) {
      refreshTokens(user.refreshToken)
      .then(res => res.json())
      .then(
        (result: TokenPayload) => {
          dispatch(login({ 
            email: getEmailFromIdToken(result.id_token), 
            jwt: result.access_token,
            jwtId: result.id_token,
            expiresAt: Date.now() + (result.expires_in * 1000)
          }))   
        },
        (error) => {
          console.log('Could not refresh... please relogin')
        })
      } 
  }
  

  useInterval(
    () => {
      refreshIntervalHandler()
    },
    100000
  )

  /***************************
  * Handle Grafana Logins
  *****************************/
  const [grafLoggedIn, setGrafLoggedIn] = useState(false);
  const grafLoginWin = useRef<Window|null>();
/*
  let grafLoginPopup:any = null
  useEffect(() => {
    const listener = window.addEventListener("message", (event) => {
      if (event.data.type === 'loginReport'){
        if(event.data.data === true) {
          setGrafLoggedIn(true)
          if(grafLoginWin.current !== null && grafLoginWin.current !== undefined){
            grafLoginWin.current.close()
            navigate('/dashboards2')
          }

        }
      }
      console.log(event)
    }, false)
  }, [])
*/

  const grafLogin = () => {

    grafLoginWin.current = window.open(featureConfig.grafana_url, 'loginWindow', 'popup=yes')

  }


  const handleTalismanLogin = () => {
    if(!grafLoggedIn){
      grafLogin()
    } else {
      navigate('/dashboards2')
    }
  }

  const handleLoginLogout = (user: User | null) => {
    if(user) {

      dispatch(logout())
      window.location.href = logoutOAuth()
    } else  {
      localStorage.setItem('state_key', v4())
      window.location.href = authorizeUrl + "&state=" + localStorage.getItem('state_key');
    }
  } 

  return (
    <Navbar fixedToTop={true}>
      <NavbarGroup align={Alignment.LEFT}>
          <NavbarHeading>Filter Explorer</NavbarHeading>
      </NavbarGroup>
      <NavbarGroup align={Alignment.RIGHT}>
          <NavbarDivider />
          { user != null && myOrg &&
            <Popover2
              interactionKind="click"
              placement="bottom"           
              content={
                <Menu>
                  <Switch checked={darkModeState} label="Dark Mode" onChange={() => setDarkModeState(!darkModeState)} />
                  <MenuItem  text="Home" onClick={() => navigate('/')}/>

                  <MenuItem  text="Dashboards (old)" onClick={() => navigate('/dashboards')}/>

                  <MenuItem  text="Talisman" onClick={() => navigate('/dashboards2')}/>

                  <MenuItem  text="Talisman (beta)" href="https://talisman.filterlabs.ai"/>

                  <MenuItem  text="Data Filters" onClick={() => navigate('/data-filters')}/>

                  <MenuItem  text="My Organization" onClick={() => navigate('/orgs')}/>

                  <MenuItem text="Logout" onClick={() => handleLoginLogout(user)} />
                </Menu>
              }
              renderTarget={({ isOpen, ref, ...targetProps }) => (
                  <Button {...targetProps} elementRef={ref} minimal={true}  icon="user" text={ user.email }/>
              )}
              
            />
          }
          { user == null &&
            <Button className="bp4-minimal" icon="user" text={ user ? 'Logout' : 'Login'} onClick={() => handleLoginLogout(user)} />
          }
          { user !== null && myOrg == null &&
            <Button className="bp4-minimal" icon="user" text='Logout' onClick={() => handleLoginLogout(user)} />
          }
      </NavbarGroup>
    </Navbar>);
}

